import {
  GET_CUSTOMER_AIRTIME_BALANCE,
  GET_CUSTOMER_BALANCE_SUMMARY,
  GET_CUSTOMER_BUNDLE_BALANCE,
  GET_CUSTOMER_INFO,
  GET_CUSTOMER_RECHARGE_HISTORY,
} from "config/endpoint";
import { HTTP } from "functions/http";
import { GetCustomerRechargeHistoryProps } from "_interfaces/http-requests/customer";

export const GetCustomerInfo = () => {
  return HTTP({
    Method: "GET",
    Url: GET_CUSTOMER_INFO,
  });
};

export const GetCustomerBalanceSummary = () => {
  return HTTP({
    Method: "GET",
    Url: GET_CUSTOMER_BALANCE_SUMMARY,
  });
};

export const GetCustomerRechargeHistory = ({
  DATA,
}: GetCustomerRechargeHistoryProps) => {
  return HTTP({
    Method: "POST",
    Url: GET_CUSTOMER_RECHARGE_HISTORY,
    Data: DATA,
  });
};

export const GetCustomerAirtimeBalance = () => {
  return HTTP({
    Method: "GET",
    Url: GET_CUSTOMER_AIRTIME_BALANCE,
  });
};

export const GetCustomerBundleBalance = () => {
  return HTTP({
    Method: "GET",
    Url: GET_CUSTOMER_BUNDLE_BALANCE,
  });
};
