import BundleBalanceLayout from "component/my-account/bundle-balance";
import MyAccountLayout from "parts/myAccountLayout";

const BundleBalance = () => {
  return (
    <>
      <MyAccountLayout>
        <BundleBalanceLayout />
      </MyAccountLayout>
    </>
  );
};

export default BundleBalance;
