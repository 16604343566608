import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import { GetCustomerBundleBalance } from "functions/http-requests/customer";
import SectionTitle from "parts/sectionTitle";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { toggleLoading } from "state/slice/loading";
import { BundleBalanceProps } from "_interfaces/bundle-balance";

import BalanceCard from "./balanceCard";

const BundleBalanceLayout = () => {
  const Dispatch = useDispatch();

  const [isLoaded, setIsLoaded] = useState<boolean>(false);
  const [state, setState] = useState<
    BundleBalanceProps["countersSummary"][] | null
  >(null);

  const [refresh, setRefresh] = useState<boolean>(false);

  const handleRefresh = () => setRefresh(!refresh);

  // FETCH BALANCE
  useEffect(() => {
    let fetchBalance: (() => void) | null = () => {
      setIsLoaded(false);
      Dispatch(toggleLoading(true));
      GetCustomerBundleBalance()
        .then((res) => {
          let DATA: BundleBalanceProps["countersSummary"][] =
            res?.data?.data?.countersSummary;
          setState(DATA);
        })
        .catch(() => {
          setState(null);
        })
        .finally(() => {
          Dispatch(toggleLoading(false));
          setIsLoaded(true);
        });
    };
    fetchBalance();
    return () => {
      fetchBalance = null;
    };
  }, [refresh]);

  return (
    <>
      <Box
        display="flex"
        flexWrap="wrap"
        gap={2}
        justifyContent="space-between"
        alignItems="center"
      >
        <SectionTitle title="Bundle Balance" />
      </Box>
      {isLoaded ? (
        <Box pt={5} className="fm-poppins">
          {!state ? (
            <>
              <Box textAlign="center">
                <Typography pb={1} fontSize={24}>
                  Balance Not Found
                </Typography>
              </Box>
            </>
          ) : (
            <Grid container spacing={3} justifyContent="center">
              {state?.map((item, i: number) => (
                <Grid key={i} item xs={12} lg={6}>
                  <BalanceCard data={item} />
                </Grid>
              ))}
            </Grid>
          )}
        </Box>
      ) : (
        <></>
      )}
    </>
  );
};

export default BundleBalanceLayout;
