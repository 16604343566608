import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { formatPriceWithDelimiter } from "functions/helper";
import React from "react";
import { AirtimeBalanceProps } from "_interfaces/airtime-balance";

interface Props {
  data: AirtimeBalanceProps;
}
const BalanceCard: React.FC<Props> = ({ data }) => {
  return (
    <>
      <Box className="fc-grey">
        <Box
          sx={{
            boxShadow: "0 .25rem 1rem rgba(0,0,0,.08)",
            borderRadius: 3,
            padding: 3,
          }}
        >
          <Box
            display="flex"
            flexWrap="wrap"
            justifyContent="space-between"
            alignItems="center"
          >
            <Box>
              <Typography fontWeight={400} fontSize={12}>
                Account ID
              </Typography>
              <Typography fontWeight={600} className="fc-black-secondary">
                {data?.accountId}
              </Typography>
            </Box>

            <Box>
              <Typography fontWeight={400} fontSize={12}>
                Account Type
              </Typography>
              <Typography fontWeight={600} className="fc-black-secondary">
                {data?.accountTypeName}
              </Typography>
            </Box>
          </Box>
          <Box pt={3} textAlign="center">
            <Typography
              fontSize={36}
              fontWeight={700}
              className="fc-black"
              lineHeight={1.2}
            >
              {data?.balance
                ? formatPriceWithDelimiter(data.balance, false)
                : 0}

              <Box component="small" fontSize={14} fontWeight={500}>
                {data?.currencyShortName}
              </Box>
            </Typography>
            <Typography fontWeight={400} fontSize={12}>
              Airtime Balance
            </Typography>
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default BalanceCard;
