import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import { SxProps } from "@mui/material/styles";
import Typography from "@mui/material/Typography";
import { formatPriceWithDelimiter } from "functions/helper";
import moment from "moment";
import React from "react";
import { BundleBalanceProps } from "_interfaces/bundle-balance";

interface Props {
  data: BundleBalanceProps["countersSummary"];
}
const BalanceCard: React.FC<Props> = ({ data }) => {
  return (
    <>
      <Box
        sx={{
          boxShadow: "0 .25rem 1rem rgba(0,0,0,.08)",
          borderRadius: 3,
          padding: 3,
        }}
      >
        <Grid container spacing={1}>
          <Grid item xs={12}>
            <RenderRow
              label="Counter Name"
              value={`${data?.counterName}`}
              valueSx={{ fontWeight: 600 }}
            />
          </Grid>
          <Grid item xs={6}>
            <RenderRow label="Counter Type" value={`${data?.counterTypeId}`} />
          </Grid>
          <Grid item xs={6}>
            <RenderRow label="Service Type" value={`${data?.serviceTypeId}`} />
          </Grid>
          <Grid item xs={6}>
            <RenderRow
              label="Actual Value"
              value={`${
                data?.actualValue
                  ? formatPriceWithDelimiter(data.actualValue, false)
                  : 0
              }`}
            />
          </Grid>
          <Grid item xs={6}>
            <RenderRow
              label="Used Value"
              value={`${
                data?.usedValue
                  ? formatPriceWithDelimiter(data.usedValue, false)
                  : 0
              }`}
            />
          </Grid>
          <Grid item xs={6}>
            <RenderRow label="Charging Unit" value={`${data?.chargingUnit}`} />
          </Grid>
          <Grid item xs={6}>
            <RenderRow
              label="Expiry Date"
              value={`${moment(data?.expiryDate).format("Do MMM YYYY")}`}
            />
          </Grid>
        </Grid>
      </Box>
    </>
  );
};

export default BalanceCard;

interface RowProps {
  label: string;
  value: string | number;
  valueSx?: SxProps;
}
const RenderRow: React.FC<RowProps> = ({ label, value, valueSx }) => {
  return (
    <>
      <Box>
        <Typography fontSize={12} className="fc-grey">
          {label}
        </Typography>
        <Typography sx={valueSx}>{value}</Typography>
      </Box>
    </>
  );
};
