import AirtimeBalanceLayout from "component/my-account/airtime-balance";
import MyAccountLayout from "parts/myAccountLayout";

const AirtimeBalance = () => {
  return (
    <>
      <MyAccountLayout>
        <AirtimeBalanceLayout />
      </MyAccountLayout>
    </>
  );
};

export default AirtimeBalance;
