import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import { GetCustomerAirtimeBalance } from "functions/http-requests/customer";
import SectionTitle from "parts/sectionTitle";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { toggleLoading } from "state/slice/loading";
import { AirtimeBalanceProps } from "_interfaces/airtime-balance";
import AirtimeRecharge from "./airtimeRecharge";
import BalanceCard from "./balanceCard";

const AirtimeBalanceLayout = () => {
  const Dispatch = useDispatch();

  const [isLoaded, setIsLoaded] = useState<boolean>(false);
  const [state, setState] = useState<AirtimeBalanceProps[] | null>([]);

  const [openAirtimeRecharge, setOpenAirtimeRecharge] =
    useState<boolean>(false);

  const handleToggleAirtimeRecharge = () =>
    setOpenAirtimeRecharge(!openAirtimeRecharge);

  const [refresh, setRefresh] = useState<boolean>(false);

  const handleRefresh = () => setRefresh(!refresh);

  // FETCH BALANCE
  useEffect(() => {
    let fetchBalance: (() => void) | null = () => {
      setIsLoaded(false);
      Dispatch(toggleLoading(true));
      GetCustomerAirtimeBalance()
        .then((res) => {
          let DATA: AirtimeBalanceProps[] = res?.data?.data;
          setState(DATA);
        })
        .catch(() => {
          setState(null);
        })
        .finally(() => {
          Dispatch(toggleLoading(false));
          setIsLoaded(true);
        });
    };
    fetchBalance();
    return () => {
      fetchBalance = null;
    };
  }, [refresh]);

  return (
    <>
      <Box
        display="flex"
        flexWrap="wrap"
        gap={2}
        justifyContent="space-between"
        alignItems="center"
      >
        <SectionTitle title="Airtime Balance" />

        <Box
          flex="1 1 auto"
          display="flex"
          flexWrap="wrap"
          gap={2}
          justifyContent="flex-end"
          alignItems="center"
        >
          <Button
            onClick={handleToggleAirtimeRecharge}
            variant="contained"
            sx={{
              borderRadius: "4px",
              padding: "8px 12px",
            }}
          >
            Recharge Airtime
          </Button>
        </Box>
      </Box>
      {isLoaded ? (
        <Box pt={5} className="fm-poppins">
          {!state ? (
            <>
              <Box textAlign="center">
                <Typography pb={1} fontSize={24}>
                  Balance Not Found
                </Typography>
              </Box>
            </>
          ) : (
            <Grid container spacing={5} justifyContent="center">
              {state?.map((item, i: number) => (
                <Grid key={i} item xs={12} lg={6}>
                  <BalanceCard data={item} />
                </Grid>
              ))}
            </Grid>
          )}
        </Box>
      ) : (
        <></>
      )}

      {/* Airtime RECHARGE */}
      <Dialog
        open={openAirtimeRecharge}
        onClose={handleToggleAirtimeRecharge}
        fullWidth
        maxWidth="sm"
      >
        <AirtimeRecharge
          handleRefresh={handleRefresh}
          handleClose={handleToggleAirtimeRecharge}
        />
      </Dialog>
    </>
  );
};

export default AirtimeBalanceLayout;
